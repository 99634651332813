import { IKind } from '@/types/general'

export const NOAUTHD_URL = process.env.REACT_APP_NOAUTHD_URL
export const WEB_PUSH_PUBKEY = process.env.REACT_APP_WEB_PUSH_PUBKEY
export const DOMAIN = process.env.REACT_APP_DOMAIN
export const ADMIN_DOMAIN = process.env.REACT_APP_ADMIN_DOMAIN
export const RELAY = process.env.REACT_APP_RELAY || 'wss://relay.nsec.app'
export const NIP46_RELAYS = [RELAY]
export const NSEC_APP_NPUB = process.env.REACT_APP_NSEC_APP_NPUB

export const RELOAD_STORAGE_KEY = 'reload'

export const ACTIONS: { [type: string]: string } = {
  basic: 'Basic permissions',
  get_public_key: 'Get public key',
  sign_event: 'Sign event',
  connect: 'Connect',
  nip04_encrypt: 'Encrypt message',
  nip04_decrypt: 'Decrypt message',
  nip44_encrypt: 'Encrypt message (NIP-44)',
  nip44_decrypt: 'Decrypt message (NIP-44)',
}

export const APP_NSEC_SIZE = {
  BIG: 'big',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  EXTRA_SMALL: 'extra-small',
} as const

export const EVENT_KINDS: Map<number, string> = new Map([
  [0, 'User Metadata'],
  [1, 'Short Text Note'],
  [2, 'Recommend Relay (deprecated)'],
  [3, 'Follows'],
  [4, 'Encrypted Direct Messages'],
  [5, 'Event Deletion Request'],
  [6, 'Repost'],
  [7, 'Reaction'],
  [8, 'Badge Award'],
  [9, 'Chat Message'],
  [10, 'Group Chat Threaded Reply (deprecated)'],
  [11, 'Thread'],
  [12, 'Group Thread Reply (deprecated)'],
  [13, 'Seal'],
  [14, 'Direct Message'],
  [16, 'Generic Repost'],
  [17, 'Reaction to a website'],
  [20, 'Picture'],
  [21, 'Video Event'],
  [22, 'Short-form Portrait Video Event'],
  [40, 'Channel Creation'],
  [41, 'Channel Metadata'],
  [42, 'Channel Message'],
  [43, 'Channel Hide Message'],
  [44, 'Channel Mute User'],
  [64, 'Chess (PGN)'],
  [818, 'Merge Requests'],
  [1018, 'Poll Response'],
  [1021, 'Bid'],
  [1022, 'Bid confirmation'],
  [1040, 'OpenTimestamps'],
  [1059, 'Gift Wrap'],
  [1063, 'File Metadata'],
  [1068, 'Poll'],
  [1111, 'Comment'],
  [1311, 'Live Chat Message'],
  [1617, 'Patches'],
  [1621, 'Issues'],
  [1622, 'Replies'],
  [1630, 'Status'],
  [1971, 'Problem Tracker'],
  [1984, 'Reporting'],
  [1985, 'Label'],
  [1986, 'Relay reviews'],
  [1987, 'AI Embeddings / Vector lists'],
  [2003, 'Torrent'],
  [2004, 'Torrent Comment'],
  [2022, 'Coinjoin Pool'],
  [4550, 'Community Post Approval'],
  [5000, 'Job Request'],
  [6000, 'Job Result'],
  [7000, 'Job Feedback'],
  [7374, 'Reserved Cashu Wallet Tokens'],
  [7375, 'Cashu Wallet Tokens'],
  [7376, 'Cashu Wallet History'],
  [9000, 'Group Control Events'],
  [9041, 'Zap Goal'],
  [9321, 'Nutzap'],
  [9467, 'Tidal login'],
  [9734, 'Zap Request'],
  [9735, 'Zap'],
  [9802, 'Highlights'],
  [10000, 'Mute List'],
  [10001, 'Pin List'],
  [10002, 'Relay List Metadata'],
  [10003, 'Bookmark List'],
  [10004, 'Communities List'],
  [10005, 'Public Chats List'],
  [10006, 'Blocked Relays List'],
  [10007, 'Search Relays List'],
  [10009, 'User Groups'],
  [10013, 'Private Event Relay List'],
  [10015, 'Interests List'],
  [10019, 'Nutzap Mint Recommendation'],
  [10030, 'User Emoji List'],
  [10050, 'Relay List to Receive DMs'],
  [10063, 'User Server List'],
  [10096, 'File Storage Server List'],
  [13194, 'Wallet Info'],
  [17375, 'Cashu Wallet Event'],
  [21000, 'Lightning Pub RPC'],
  [22242, 'Client Authentication'],
  [23194, 'Wallet Request'],
  [23195, 'Wallet Response'],
  [24133, 'Nostr Connect'],
  [24242, 'Blobs Stored on Mediaservers'],
  [27235, 'HTTP Auth'],
  [30000, 'Follow Sets'],
  [30001, 'Generic Lists (deprecated)'],
  [30002, 'Relay Sets'],
  [30003, 'Bookmark Sets'],
  [30004, 'Curation Sets'],
  [30005, 'Video Sets'],
  [30007, 'Kind Mute Sets'],
  [30008, 'Profile Badges'],
  [30009, 'Badge Definition'],
  [30015, 'Interest Sets'],
  [30017, 'Create or Update a Stall'],
  [30018, 'Create or Update a Product'],
  [30019, 'Marketplace UI/UX'],
  [30020, 'Product Sold as an Auction'],
  [30023, 'Long-form Content'],
  [30024, 'Draft Long-form Content'],
  [30030, 'Emoji Sets'],
  [30040, 'Modular Article Header'],
  [30041, 'Modular Article Content'],
  [30063, 'Release Artifact Sets'],
  [30078, 'Application-specific Data'],
  [30267, 'App Curation Sets'],
  [30311, 'Live Event'],
  [30315, 'User Statuses'],
  [30388, 'Slide Set'],
  [30402, 'Classified Listing'],
  [30403, 'Draft Classified Listing'],
  [30617, 'Repository Announcements'],
  [30618, 'Repository State Announcements'],
  [30818, 'Wiki Article'],
  [30819, 'Redirects'],
  [31234, 'Draft Event'],
  [31388, 'Link Set'],
  [31890, 'Feed'],
  [31922, 'Date-Based Calendar Event'],
  [31923, 'Time-Based Calendar Event'],
  [31924, 'Calendar'],
  [31925, 'Calendar Event RSVP'],
  [31989, 'Handler Recommendation'],
  [31990, 'Handler Information'],
  [32267, 'Software Application'],
  [34550, 'Community Definition'],
  [38383, 'Peer-to-Peer Order Events'],
  [39000, 'Group Metadata Events'],
])

export const RANGED_EVENT_KINDS = [
  { start: 1630, end: 1633, label: 'Status' },
  { start: 5000, end: 5999, label: 'Job Request' },
  { start: 6000, end: 6999, label: 'Job Result' },
  { start: 9000, end: 9030, label: 'Group Control Events' },
  { start: 39000, end: 39009, label: 'Group metadata events' },
]

export const KINDS: IKind[] = Array.from(EVENT_KINDS.entries()).map(([key, value]) => {
  return {
    kind: key,
    name: value,
  }
})
